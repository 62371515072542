<template>
  <div class="container">
    <h4 class="blue-text font-weight-bold">パスワード変更</h4>
    <b-alert
      class="m-0 mt-3 light-grey-outline grey-text"
      variant="warning"
      show
      v-if="isGoogleAccount"
    >
      <h5 class="m-0">
        <font-awesome-icon
          class="mr-3"
          :icon="{ prefix: 'fas', iconName: 'exclamation-circle' }"
          style="height: 25px; vertical-align: middle"
        />
        <span class="mt-2">
          この機能はGoogle認証を使わずに登録したアカウントで利用可能です。
        </span>
      </h5>
    </b-alert>
    <div class="row" v-else>
      <div class="col-12 col-md-12 col-lg-10 col-xl-5">
        <form v-on:submit="resetPassword">
          <div class="row">
            <div class="col-12">
              <div class="mt-4">
                <p class="m-0 font-weight-bold">現在のパスワード</p>
                <div class="d-flex">
                  <input
                    id="current-password"
                    class="form-control d-inline shadow-1"
                    type="password"
                    v-model="currentPassword"
                    minlength="2"
                    maxlength="100"
                    placeholder="パスワードを入力して下さい"
                    style="
                      border-top-right-radius: 0 !important;
                      border-bottom-right-radius: 0 !important;
                      border-right: 0;
                    "
                    required
                  />
                  <button
                    class="btn btn-ds transparent m-0 d-inline shadow-1"
                    type="button"
                    style="
                      height: 56px;
                      width: 56px;
                      border-top-left-radius: 0;
                      border-bottom-left-radius: 0;
                      border-top: 1px solid #ced4da;
                      border-bottom: 1px solid #ced4da;
                      border-right: 1px solid #ced4da;
                    "
                    v-on:click="showPasswordToggle('current-password')"
                    v-waves.light
                  >
                    <font-awesome-icon
                      :icon="{
                        prefix: 'fas',
                        iconName: currentPasswordIcon,
                      }"
                    />
                  </button>
                </div>
              </div>
            </div>
            <div class="col-12">
              <div class="mt-4">
                <p class="m-0 font-weight-bold">パスワードは</p>
                <div class="d-flex">
                  <input
                    id="new-password"
                    class="form-control d-inline shadow-1"
                    type="password"
                    v-model="newPassword"
                    v-on:keyup="hintChecker"
                    minlength="2"
                    maxlength="100"
                    placeholder="パスワードを入力して下さい"
                    style="
                      border-top-right-radius: 0 !important;
                      border-bottom-right-radius: 0 !important;
                      border-right: 0;
                    "
                    required
                  />
                  <button
                    class="btn btn-ds transparent m-0 d-inline shadow-1"
                    type="button"
                    style="
                      height: 56px;
                      width: 56px;
                      border-top-left-radius: 0;
                      border-bottom-left-radius: 0;
                      border-top: 1px solid #ced4da;
                      border-bottom: 1px solid #ced4da;
                      border-right: 1px solid #ced4da;
                    "
                    v-on:click="showPasswordToggle('new-password')"
                    v-waves.light
                  >
                    <font-awesome-icon
                      :icon="{
                        prefix: 'fas',
                        iconName: newPasswordIcon,
                      }"
                    />
                  </button>
                </div>
              </div>
              <ul class="mt-3 ml-4">
                <li
                  :class="
                    rules.length
                      ? 'light-grey-text text-decoration-strike'
                      : 'light-grey-text'
                  "
                >
                  パスワードは8文字以上必要です。
                </li>
                <li
                  :class="
                    rules.uppercase
                      ? 'light-grey-text text-decoration-strike'
                      : 'light-grey-text'
                  "
                >
                  パスワードは1文字以上大文字が必要です。
                </li>
                <li
                  :class="
                    rules.lowercase
                      ? 'light-grey-text text-decoration-strike'
                      : 'light-grey-text'
                  "
                >
                  パスワードは1文字以上小文字が必要です。
                </li>
              </ul>
            </div>
            <div class="col-12">
              <div class="mt-4">
                <p class="m-0 font-weight-bold">パスワード確認</p>
                <div class="d-flex">
                  <input
                    id="confirm-password"
                    class="form-control d-inline shadow-1"
                    type="password"
                    v-model="confirmPassword"
                    minlength="2"
                    maxlength="100"
                    placeholder="確認パスワードを入力してください"
                    style="
                      border-top-right-radius: 0 !important;
                      border-bottom-right-radius: 0 !important;
                      border-right: 0;
                    "
                    required
                  />
                  <button
                    class="btn btn-ds transparent m-0 d-inline shadow-1"
                    type="button"
                    style="
                      height: 56px;
                      width: 56px;
                      border-top-left-radius: 0;
                      border-bottom-left-radius: 0;
                      border-top: 1px solid #ced4da;
                      border-bottom: 1px solid #ced4da;
                      border-right: 1px solid #ced4da;
                    "
                    v-on:click="showPasswordToggle('confirm-password')"
                    v-waves.light
                  >
                    <font-awesome-icon
                      :icon="{
                        prefix: 'fas',
                        iconName: confirmPasswordIcon,
                      }"
                    />
                  </button>
                </div>
              </div>
            </div>
            <div class="col-12">
              <b-alert
                class="m-0 mt-3 light-grey-outline grey-text"
                variant="warning"
                show
                v-if="formMessage !== null"
              >
                {{ formMessage }}
              </b-alert>
            </div>
          </div>
          <div class="row mt-4">
            <div class="col-12 col-md-6 col-lg-6 col-xl-6 mt-2">
              <router-link
                :to="{
                  name: 'userRoute-dashboard',
                }"
              >
                <button class="btn btn-ds grey white-text">キャンセル</button>
              </router-link>
            </div>
            <div class="col-12 col-md-6 col-lg-6 col-xl-6 mt-2">
              <button
                class="btn btn-ds dark-blue white-text"
                type="submit"
                v-waves.light
              >
                更新
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>

    <b-modal
      id="password-update-success-modal"
      class="border-radius-2"
      size="md"
      hide-footer
      hide-header
      no-close-on-backdrop
      no-close-on-esc
      centered
    >
      <h5
        class="grey-text dark-blue-text-hover float-right pl-1 pr-1"
        v-on:click="logout"
      >
        <font-awesome-icon :icon="{ prefix: 'fas', iconName: 'times' }" />
      </h5>
      <div class="container text-center">
        <img class="mt-4" src="/img/icons/ico-info.svg" alt="ico-info" />
        <h5 class="mt-4 mb-0 dark-blue-text font-weight-bold">
          パスワードの変更に成功しました！
        </h5>
        <p class="grey-text">
          パスワードの更新を行いました.
          ログイン画面から、再度ログインを行ってください。
        </p>
        <div class="row mb-3 mt-5">
          <div class="col-12">
            <button
              class="btn btn-ds dark-blue white-text pt-2 pb-2"
              type="button"
              v-waves.light
              v-on:click="logout"
            >
              ログイン画面へ
            </button>
          </div>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
// Import Vuex/Store
import { mapGetters } from 'vuex';

// Import Services
import { cus_userService_UpdateUserPasswordByID } from '../../services/customer/users';

export default {
  metaInfo() {
    return {
      title: 'Digital Staging - Profile | Reset Password',
    };
  },

  data() {
    return {
      isGoogleAccount: false,
      currentPassword: null,
      newPassword: null,
      confirmPassword: null,
      currentPasswordIcon: 'eye-slash',
      newPasswordIcon: 'eye-slash',
      confirmPasswordIcon: 'eye-slash',
      rules: {
        length: false,
        uppercase: false,
        lowercase: false,
      },
      formMessage: null,
    };
  },

  computed: {
    ...mapGetters(['currentUser', 'isLoggedIn']),
  },

  mounted() {
    setInterval(() => {
      this.googleAccountIsSignedIn();
    }, 500);
  },

  methods: {
    async googleAccountIsSignedIn() {
      let _this = this;

      try {
        let isAuthorized = await _this.$gAuth.isAuthorized;

        _this.isGoogleAccount = isAuthorized;
      } catch (error) {
        console.error(error);

        _this.isGoogleAccount = false;

        return null;
      }
    },

    showPasswordToggle(element) {
      let _element = document.querySelector(`#${element}`);

      if (_element.getAttribute('type') === 'password') {
        _element.setAttribute('type', 'text');

        if (element === 'current-password') {
          this.currentPasswordIcon = 'eye';
        }

        if (element === 'new-password') {
          this.newPasswordIcon = 'eye';
        }

        if (element === 'confirm-password') {
          this.confirmPasswordIcon = 'eye';
        }
      } else {
        _element.setAttribute('type', 'password');

        if (element === 'current-password') {
          this.currentPasswordIcon = 'eye-slash';
        }

        if (element === 'new-password') {
          this.newPasswordIcon = 'eye-slash';
        }

        if (element === 'confirm-password') {
          this.confirmPasswordIcon = 'eye-slash';
        }
      }
    },

    hintChecker() {
      this.formMessage = null;

      if (this.newPassword !== null && this.newPassword.length > 0) {
        if (this.newPassword.length >= 8) {
          this.rules.length = true;
        } else {
          this.rules.length = false;
        }

        if (/[A-Z]/.test(this.newPassword)) {
          this.rules.uppercase = true;
        } else {
          this.rules.uppercase = false;
        }

        if (/[a-z]/.test(this.newPassword)) {
          this.rules.lowercase = true;
        } else {
          this.rules.lowercase = false;
        }

      } else {
        this.rules.length = false;
        this.rules.uppercase = false;
        this.rules.lowercase = false;
      }
    },

    resetPassword(e) {
      let _this = this;

      e.preventDefault();

      _this.formMessage = null;

      _this.$store.state.modalLoaderMessage = 'パスワードのリセット中';
      _this.$bvModal.show('modal-loader');

      if (_this.newPassword === _this.confirmPassword) {
        if (_this.newPassword.length > 7) {
          if (/[A-Z]/.test(_this.newPassword)) {
            if (/[a-z]/.test(_this.newPassword)) {
              let accessToken = localStorage.getItem('_accessToken');

              cus_userService_UpdateUserPasswordByID({
                id: _this.currentUser.id,
                current_password: _this.currentPassword,
                password: _this.newPassword,
                password_confirmation: _this.confirmPassword,
                token: accessToken,
              })
                .then((response) => {
                  console.log(response);

                  localStorage.removeItem('_accessToken');

                  setTimeout(() => {
                    _this.$bvModal.hide('modal-loader');
                    _this.$bvModal.show('password-update-success-modal');
                  }, 500);
                })
                .catch((error) => {
                  console.log(error);

                  setTimeout(() => {
                    _this.$bvModal.hide('modal-loader');
                  }, 500);

                  _this.formMessage = '現在のパスワードが間違っています。';
                });
            } else {
              setTimeout(() => {
                _this.$bvModal.hide('modal-loader');
              }, 500);

              _this.formMessage = 'パスワードは1文字以上小文字が必要です。';
            }
          } else {
            setTimeout(() => {
              _this.$bvModal.hide('modal-loader');
            }, 500);

            _this.formMessage = 'パスワードは1文字以上大文字が必要です。';
          }
        } else {
          setTimeout(() => {
            _this.$bvModal.hide('modal-loader');
          }, 500);

          _this.formMessage = 'パスワードは8文字以上必要です。';
        }
      } else {
        setTimeout(() => {
          _this.$bvModal.hide('modal-loader');
        }, 500);

        _this.formMessage = 'パスワード確認が一致しません。';
      }
    },

    logout() {
      let _this = this;

      _this.$bvModal.hide('password-update-success-modal');

      _this.$store.state.modalLoaderMessage = 'サインアウト中';
      _this.$bvModal.show('modal-loader');

      _this.$store.dispatch('setLogout');

      setTimeout(() => {
        _this.$bvModal.hide('modal-loader');

        _this.$router.push({ name: 'anonymousRoute-login' });
      }, 1000);
    },
  },
};
</script>

<style scoped>
/* Init Style */
/* Custom Style */
</style>
