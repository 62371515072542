import { render, staticRenderFns } from "./Profile-Password-Update.vue?vue&type=template&id=499c471c&scoped=true&"
import script from "./Profile-Password-Update.vue?vue&type=script&lang=js&"
export * from "./Profile-Password-Update.vue?vue&type=script&lang=js&"
import style0 from "./Profile-Password-Update.vue?vue&type=style&index=0&id=499c471c&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "499c471c",
  null
  
)

export default component.exports